import React from "react"
import styles from "./styles.scss"
import cx from "classnames"
import Tooltip from "components/Tooltip"
import {
  EditModelAdvertAreaContentEditorBehaviours,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles,
} from "schema"
import {
  useVerseEditingContextDispatch,
  useVerseEditingContextState,
} from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/VerseEditingModal/VerseModalContext"
import Button from "components/Button"
import { MagnifyingGlassWithBookmark } from "asset/svg/SvgComponents"
import TextAlignSelector from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/AreasContainer/Areas/AreaContainer/HtmlInput/Toolbar/TextAlignSelector"
import ColumnsButton from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/AreasContainer/Areas/AreaContainer/HtmlInput/Toolbar/ColumnsButton"
import { faCross } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import ScalaTextInput from "../ScalaTextInput"
import MarginLeftRightInput from "../MarginLeftRightInput"
import DropdownButton from "components/DropdownButton"
import { IEditorViewBaseOnUserAndFields } from "pages/EditOrderPage/OrderEditorContainer/OrderEditoryAdvert/AreasContainer/Areas/AreaContainer/HtmlInput/ColumnView/ColumnContainer"

const Tools: React.FC<{
  isLocked: boolean
  isFEUser: boolean
  isVerseGalleryEnabled: boolean
  editorBehaviour: EditModelAdvertAreaContentEditorBehaviours
  handleOpenVerse: () => void
  isVersModalOpen: boolean
  isEnabledMultiColumns: boolean
  onEnterColumnEdit: () => void
  onToggleSymbolMenu: (e: React.SyntheticEvent<any>) => void
  isMenuVisible: boolean
  blockStyles: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[]
  onSelectBlockStyle: (value: string | null) => void
  editorViewBaseOnUserAndFields: IEditorViewBaseOnUserAndFields
  isXmlTextReadonly: boolean
}> = (props) => {
  const { TEXT_HTML_VERSE } = EditModelAdvertAreaContentEditorBehaviours

  return (
    <div
      className={cx(styles.rowWrapper, {
        [styles.isLockedActive]: props.isLocked,
        [styles.feView__rowWrapper]: props.isFEUser,
      })}
    >
      {props.isVerseGalleryEnabled &&
      !props.editorViewBaseOnUserAndFields.isFields &&
      !props.isXmlTextReadonly ? (
        <Tooltip
          id={"ScalaTextInput"}
          text={
            props.editorBehaviour === TEXT_HTML_VERSE
              ? "Sök och spara verser/texter"
              : "Sök och spara texter"
          }
          bgColor={"#fff"}
          className={styles.tooltip}
          delayShow={750}
        >
          <SearchVerseButton
            onClickOpen={props.handleOpenVerse}
            isModalOpen={props.isVersModalOpen}
            isFEUser={props.isFEUser}
          />
        </Tooltip>
      ) : null}

      <TextAlignSelector isFEUser={props.isFEUser} />

      {props.isEnabledMultiColumns && (
        <ColumnsButton onClick={props.onEnterColumnEdit} />
      )}
      {!props.editorViewBaseOnUserAndFields.isFields &&
        !props.isXmlTextReadonly && (
          <Tooltip
            id={"ScalaTextInput"}
            text={"Lägg till ikon"}
            bgColor={"#fff"}
            className={styles.tooltip}
            delayShow={750}
          >
            <button
              onMouseDown={props.onToggleSymbolMenu}
              className={cx(styles.iconsButton, {
                [styles.isActive]: props.isMenuVisible,
              })}
            >
              <FontAwesomeIcon icon={faCross} />
            </button>
          </Tooltip>
        )}

      <BlockStylesDropdown
        blockStyles={props.blockStyles}
        onSelectBlockStyle={props.onSelectBlockStyle}
      />
      <ScalaTextInput isFEUser={props.isFEUser} />
      <MarginLeftRightInput isFEUser={props.isFEUser} />
    </div>
  )
}

const BlockStylesDropdown: React.FC<{
  blockStyles: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_common_blockStyles[]
  onSelectBlockStyle: (value: string | null) => void
}> = (props) => {
  const [t] = useTranslation(["editOrder"])
  if (props.blockStyles.length === 0) {
    return <React.Fragment />
  }
  return (
    <DropdownButton
      rightIcon="angle-down"
      label={t("editOrder:blockStyles")}
      options={props.blockStyles.map((e) => {
        return {
          label: e.name,
          value: e.id,
        }
      })}
      contentClassName={styles.dropdownContentStyles}
      togglerClassName={styles.dropdownStyles}
      variant={"none"}
      onOptionClick={(option) => {
        props.onSelectBlockStyle(option.value.replace(/<[^>]*>?/gm, ""))
      }}
    />
  )
}

const MagnifyingGlassWithBookmarkSvg = () => (
  <div className={styles.MagnifyingClassIcon}>
    {React.cloneElement(<MagnifyingGlassWithBookmark />, {
      size: "22px",
      color: "#ACACAC",
    })}
  </div>
)

const SearchVerseButton: React.FC<{
  onClickOpen: () => void
  isModalOpen: boolean
  isFEUser: boolean
}> = (props) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const isModalVisible = useVerseEditingContextState(
    (state) => state.data.isModalVisible
  )
  const dispatch = useVerseEditingContextDispatch()
  const onPressSearchVerse = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      dispatch({ type: "toggleModalWithRect", payload: { rect } })
    } else {
      dispatch({ type: "toggleModal" })
    }
  }

  const onOpen = () => {
    props.onClickOpen()
    onPressSearchVerse()
  }
  return (
    <div
      className={cx(styles.buttonSearchWrapper, {
        [styles.feView__adstateEnabled]: props.isFEUser,
      })}
      ref={(e) => (ref.current = e)}
    >
      <Button
        variant="primary"
        onClick={onOpen}
        //icon="search"
        className={cx(styles.buttonSearch, {
          ["styles.buttonSearchActive"]:
            props.isModalOpen === true && isModalVisible === true,
        })}
        id={"verse-model-button"}
      >
        {MagnifyingGlassWithBookmarkSvg()}
      </Button>
    </div>
  )
}

export default Tools
