import gql from "graphql-tag"
import ORDER_EDITOR_CONTENT_VALUE_FRAGMENT from "./OrderEditorContentValueFragment"
import RECTANGLE_FRAGMENT from "./RectangleFragment"

const AREA_FRAGMENT = gql`
  fragment AreaFragment on EditModelAdvertArea {
    id
    header
    content {
      validateErrors {
        error
        validationText
      }
      value {
        ...OrderEditorContentValueFragment
      }
      editor {
        type
        behaviour
        access {
          add
          modify
          statusAdd
        }
        common {
          isEnabledAdjustMargins
          isEnabledUpdateOrdersContent
          isEnabledMultiColumns
          isMarginLeftRightOffset
          allowedMarginLeftRightOffsetInterval {
            type
            maxValue
            minValue
            intervalValue
          }
          blockStyles {
            id
            name
          }
        }
        text {
          defaultTextAdjustment
          allowedFontSizes(unit: POINT) {
            values
          }
          allowedFontStyles
          allowedFontWeights
          allowedLineHeights(unit: POINT) {
            values
          }
          allowedFontFamilies
          allowedTextAdjustments
          allowedFontSizeInterval {
            type
            intervalValue
          }
          allowedLineHeightInterval {
            type
            intervalValue
          }
          isTextScaleWidthOffset
          allowedTextScaleWidthOffsetInterval {
            intervalValue
            minValue
            maxValue
          }
          allowedCharacterGalleries {
            id
            isAdvancedUser
            items {
              id
              name
              title
              preview
              html
            }
          }
          allowedParagraphClasses {
            id
            name
          }
          allowedFontStyles
          allowedFontWeights
          isEnabledFontSize
          isEnabledFontStyle
          isEnabledLineHeight
          isEnabledFontFamily
          isEnabledFontWeight
          isEnabledLetterSpacing
          isEnabledAdvancedSettings
          isEnabledTextScaleWidthTolerance
          isEnabledGallery
        }
        image {
          minImageHeight
          maxImageHeight
          filterGalleryImageAttributeInternalIds
          filterGalleryImageAttributeNotInternalIds
          galleryImageOption
          documentFormatId
          isEnabledFreeSize
          isEnabledUpload
          adjustment {
            brightness {
              intervalValue
              maxValue
              minValue
              type
            }
            contrast {
              intervalValue
              maxValue
              minValue
              type
            }
            rotate {
              intervalValue
              maxValue
              minValue
              type
            }
          }
          mask {
            imageUrl
          }
        }
      }
    }
    style {
      margin {
        top
        left
        right
        bottom
      }
      content {
        ## mallens size
        size(unit: MILLIMETER) {
          type
          minWidth
          minHeight
          maxWidth
          maxHeight
          fixedWidth
          fixedHeight
        }
      }
      text(useStandardValues: true) {
        fontFamily
        fontSize {
          type
          value
        }
        lineHeight {
          type
          value
        }
        lineHeightEmpty {
          type
          value
        }
        letterSpacing {
          type
          value
        }
        textScaleWidthOffset {
          type
          value
        }
        textScaleWidth {
          type
          value
        }
        fontStyle
        fontWeight
      }
    }
    ...RectangleFragment
  }
  ${ORDER_EDITOR_CONTENT_VALUE_FRAGMENT}
  ${RECTANGLE_FRAGMENT}
`

export default AREA_FRAGMENT
