import * as React from "react"
import * as styles from "./styles.scss"
import RangeSlider from "components/RangeSlider"
import produce from "immer"
import cx from "classnames"
import { SliderType } from "../../../../../../../../../customSchema.interface"

const MIN_SCALE = 0.4
const MAX_SCALE = 1

const ImageSlider: React.FC<ImageSliderProps> = (props) => {
  const getScaleByValue = (value: number) => {
    // const { editorImage } = this.props
    if (!(props.minImageHeight && props.maxImageHeight)) {
      return 0
    }
    const diff = MAX_SCALE - MIN_SCALE
    return (
      ((value - props.minImageHeight) /
        (props.maxImageHeight - props.minImageHeight)) *
        diff +
      MIN_SCALE
    )
  }

  const handleSliderChange = (value: number) => {
    if (props.isSizeFree && props.isSupportUser) {
      props.setState(
        produce(props.state, (draft) => {
          draft.slider.value = value
          draft.slider.scale = getScaleByValue(value)
        })
      )
    }
    if (!props.isSizeFree) {
      props.setState(
        produce(props.state, (draft) => {
          draft.slider.value = value
          draft.slider.scale = getScaleByValue(value)
        })
      )
    }
  }

  const handleSliderChangeComplete = () => {
    if (props.isSizeFree && props.isSupportUser) {
      props.onResize(props.state.slider.value)
    }
    if (!props.isSizeFree) {
      props.onResize(props.state.slider.value)
    }
  }

  React.useEffect(() => {
    if (props.symbolSize !== props.state.slider.value) {
      if (props.symbolSize !== undefined && props.symbolSize !== null) {
        props.setState({
          slider: {
            value: props.symbolSize,
            scale: getScaleByValue(props.symbolSize),
          },
        })
      } else {
        props.setState({
          slider: {
            value: null,
            scale: null,
          },
        })
      }
    }
  }, [props.symbolSize, props.isSizeFree])

  return (
    <div className={styles.imageSliderContainer}>
      <div
        className={styles.imageWrapper}
        onClick={props.isOrderImage ? props.onOpenModal : () => {}}
      >
        {props.selectedSymbolUrl && (
          <>
            <img
              src={props.selectedSymbolUrl}
              alt=""
              className={styles.image}
            />
          </>
        )}
      </div>

      {props.isSupportUser && props.resolution ? (
        <div className={styles.resolution}>
          {" "}
          <span>{Math.round(props.resolution)} dpi</span>
        </div>
      ) : null}

      {props.maxImageHeight !== undefined &&
        props.minImageHeight !== undefined &&
        props.isOrderImage && (
          <div className={cx(styles.imageSliderWrapper)}>
            <RangeSlider
              min={props.minImageHeight}
              max={props.maxImageHeight}
              value={props.state.slider.value}
              onChange={handleSliderChange}
              onChangeComplete={handleSliderChangeComplete}
              rightIcon="image"
              leftIcon="image"
              rightIconStyle={styles.rightIcon}
              className={cx(styles.slider,{
                [styles.fe__slider]: props.isFEUser
              })}
              defaultHeightMM={props.defaultHeightMM}
            />
            <div className={styles.minMaxContainer}>
              <span>{Math.round(props.minImageHeight)} mm</span>
              <span>
                {!props.state.slider.value
                  ? Math.round(props.defaultHeightMM!)
                  : Math.round(props.state.slider.value!)}{" "}
                mm
              </span>
              <span>{Math.round(props.maxImageHeight)} mm</span>
            </div>
          </div>
        )}
    </div>
  )
}

interface ImageSliderProps {
  selectedSymbolUrl?: string
  minImageHeight?: number
  maxImageHeight?: number
  symbolSize?: number | null
  onResize: (symbolSize: number | null) => void
  onOpenModal: () => void
  defaultHeightMM?: number | null
  state: { slider: SliderType }
  setState: any
  isSizeFree?: boolean | null
  isSupportUser?: boolean
  resolution: any
  isOrderImage: boolean
  isFEUser: boolean
}

export default ImageSlider