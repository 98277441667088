import * as React from "react"
import ConfirmationModal from "components/ConfirmationModal"
import { useTranslation } from "react-i18next"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import DELETE_ORDER from "graphql/mutations/deleteOrder"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { useHistory } from "react-router"
import { DeleteOrder, DeleteOrderVariables } from "schema"
import { produce } from "immer"

const DeleteOrderModal: React.FC<{
  onRequestClose: () => void
  isOpen: boolean
  orderId?: string
  caseFolderId: string
}> = (props) => {
  const [t] = useTranslation(["errorMessages", "editOrder"])
  const history = useHistory()
  const client = useApolloClient()
  const mutationDeleteOrder = useMutation<DeleteOrder, DeleteOrderVariables>(
    DELETE_ORDER,
    {
      client,
      context: {
        skipError: true,
      },
    }
  )
  const [query, setQuery] = React.useState<{
    data: any | null
    loading: boolean
    error: string | undefined
  }>({
    data: null,
    loading: false,
    error: undefined,
  })
  const newsPapper = localStorage.getItem('newsPapper')
  const firstOrderId = localStorage.getItem('firstOrder')

  const handleDelete = () => {
    if (props.orderId === undefined) {
      setQuery(
        produce(query, (draft) => {
          draft.loading = false
          draft.error = t("errorMessages:unknowError")
        })
      )
      return
    }
    setQuery(
      produce(query, (draft) => {
        draft.loading = true
        draft.error = undefined
      })
    )
    mutationDeleteOrder({
      variables: {
        input: {
          orderIds: [props.orderId],
        },
      },
    })
      .then((response) => {
        if (response.errors) {
          setQuery(
            produce(query, (draft) => {
              draft.loading = false
              draft.error = response.errors?.map((e) => e.message).join(", ")
            })
          )
          return
        }

        if (!response.data) {
          setQuery(
            produce(query, (draft) => {
              draft.loading = false
              draft.error = t("errorMessages:unknowError")
            })
          )
          return
        }
        
        history.push(`/editOrder/c/${props.caseFolderId}/o/${firstOrderId}`)
        localStorage.removeItem('newsPapper')
        localStorage.removeItem('firstOrder')
      })
      .catch((err) => {
        setQuery(
          produce(query, (draft) => {
            draft.loading = false
            draft.error = err.toString()
          })
        )
      })
  }
  

  return (
    <ConfirmationModal
      isOpen={props.isOpen}
      title={t("editOrder:deleteMedia")}
      text={newsPapper ? `Du är på väg att ta bort "${newsPapper}". Tryck på 'Ta bort media' om du är säker.` : t("editOrder:deleteOrderModalText")}
      okButtonText={t("editOrder:deleteMedia")}
      onRequestClose={props.onRequestClose}
      isLoading={query.loading}
      loadingText={t("editOrder:deletingMedia")}
      onOKClick={handleDelete}
      error={query.error}
    />
  )
}

export default DeleteOrderModal
