import gql from "graphql-tag"

const EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT = gql`
  fragment EditOrderPageCaseFolderFragment on CaseFolder {
    id
    editTabs {
      id
      name
      isSubTabs
      orders {
        id
        customerFriendlyId
        client {
          route {
            editPageRoute {
              clientApp
              absUrl
              relUrl
              taps5IframeAbsUrl
              taps5RelUrl
            }
          }
        }
        alerting {
          smallText
          text
          type
        }
        mediaOrder {
          isLocked
          edit {
            editModelType
          }
          documentFormat {
            id
            mediaAdaptedName : friendlyName(isShownWithMedia:true) 
            documentType {
              id
              name
            }
          }
          media {
            id
            friendlyName
          }
          status {
            color
            status
          }
        }
        document {
          id
        }
      }
    }
  }
`

export default EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT
