import React from "react"
import * as styles from "../styles.scss"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Select from "react-select"

import imagelogo from "images/teamviewerLogo.png"
import qrCodeImage from "images/support/qrCode.png"
import { useTranslation } from "react-i18next"

const RightCol: React.FC<{
  isMediaUser: boolean
}> = (props) => {
  const {t} = useTranslation("supportView")
  const onDropdownChange = (selected: { label: string; value: string }) => {
    window.open(selected.value, "_blank")
  }

  const options = [
    {
      label: t("tab1_InformationInAd"),
      value: require("images/adblock/Information.pdf"),
    },
    {
      label: t("tab1_AdDesign"),
      value: require("images/adblock/Flik1.pdf"),
    },
    {
      label: t("tab2_SymbolMeaning"),
      value: require("images/adblock/Flik2.pdf"),
    },
    {
      label: t("tab3_SymbolOverview"),
      value: require("images/adblock/Flik3_index.pdf"),
    },
    {
      label: t("tab3_symbolBlackWhite"),
      value: require("images/adblock/Flik3.pdf"),
    },
    {
      label: t("tab3_symbolColor"),
      value: require("images/adblock/Flik3_Färgsymboler.pdf"),
    },
    { label: t("tab4_newspaper"), value: require("images/adblock/Flik4.pdf") },
    {
      label: t("tab5_GiftFunds"),
      value: require("images/adblock/Flik_5__Gavofonder.pdf"),
    },
    {
      label: t("tab6_versTitle"),
      value: require("images/adblock/Flik6_Index.pdf"),
    },
    { label: t("tab6_vers"), value: require("images/adblock/Flik6.pdf") },
    {
      label: t("tab7_vers_signature"),
      value: require("images/adblock/Flik7.pdf"),
    },
    {
      label: t("tab8_vers_alphabetIndex"),
      value: require("images/adblock/Flik8_index.pdf"),
    },
    {
      label: t("tab8_vers_alphabet"),
      value: require("images/adblock/Flik8.pdf"),
    },
    {
      label: t("tab9_DesignAd"),
      value: require("images/adblock/esperado_annonser.pdf"),
    },
  ]

  return (
    <div className={styles.rightCol}>
      <div className={styles.welecomeCard}>
        <h4>{t("welcomeText")}</h4>
        <ul className={styles.contactInfo}>
          <li>
            <FontAwesomeIcon icon={faPhone} />
            <a href="tel:+46264012180">026-401-21-80</a>
          </li>
          <li>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="mailto:support@timecut.se">support@timecut.se</a>
          </li>
        </ul>
        <div className={styles.openTime}>
          <span>{t("openTime")}</span>
          <span>08:00 - 17:00</span>
        </div>
      </div>

      <div className={styles.tapsMobileWrapper}>
        <h4>MobilTaps</h4>
        <div className={styles.innerContent}>
          <a href={"https://taps5-mobile.timecut.se"} target={"_blank"}>
            MobilTaps
          </a>
          <img src={qrCodeImage} alt="timecut" width="130px" height="130px" />
        </div>
      </div>
      {!props.isMediaUser && (
        <div className={styles.minSidaContent}>
          <h4>{t("profile")}</h4>
          <ul>
            <li>
              <a
                href={require("images/minSidaPdf/min_sida_userguide_1_0.pdf")}
                target={"_blank"}
              >
                {t("downloadRelativeGuide")}
              </a>
            </li>
            <li>
              <a
                href={require("images/minSidaPdf/min_sida_agencyguide_1_0.pdf")}
                target={"_blank"}
              >
                {t("downloadOfficeGuide")}
              </a>
            </li>
          </ul>
        </div>
      )}

      <div className={styles.avantgarde}>
        <h4> {t("supportView:avantgardeTitle")}</h4>
        <span>{t("supportView:avantgardeDescribeText")}</span>
        <ul>
          <li>
          <a
                href={require("images/avantgarde/avantgarde_rapid.pdf")}
                target={"_blank"}
              >
                {t("supportView:downloadAvantgardePdf")}
              </a>
          </li>
        </ul>
      </div>

      <div className={styles.advertSample}>
        <h4>{t("adCover")}</h4>
        <span>
          {t("adCoverDes")}
        </span>
        <Select
          className={styles.sampleOptions}
          onChange={onDropdownChange}
          options={options}
          placeholder={"Välj..."}
        />
      </div>
      <div className={styles.remoteApp}>
        <h4>{t("remoteControl")}</h4>
        <span>
          {t("remoteControlDes")}
        </span>
        <div className={styles.appLink}>
          <img src={imagelogo} alt="timecut" width="60px" height="60px" />
          <a
            href={"https://www.teamviewer.com/sv/ladda-ner/windows/"}
            target="_blank"
          >
            {t("teamViewDes")}
          </a>
        </div>
      </div>
    </div>
  )
}

export default RightCol
