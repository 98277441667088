import {
  GetCurrentUserSession_currentUserSession,
  GetCurrentCustomer_currentCustomer,
} from "schema"
import GET_CURRENT_USER_SESSION from "graphql/queries/getCurrentUserSession"
import { useQuery } from "react-apollo-hooks"
import GET_CURRENT_CUSTOMER from "graphql/queries/getCurrentCustomer"
import { SessionStorageKeys } from "environmentUtils"


export type TFeatureFlag = {
  isFEUser: boolean;
  isBitnetUser: boolean
} | undefined

const AppContextDataProvider = (props: AppContextDataProviderProps) => {
  var token = sessionStorage.getItem(SessionStorageKeys.AUTH_TOKEN)
  if(token == undefined) {
    token = localStorage.getItem(SessionStorageKeys.AUTH_TOKEN);
    if( token != undefined ) sessionStorage.setItem(SessionStorageKeys.AUTH_TOKEN, token);
  } else {
    localStorage.setItem(SessionStorageKeys.AUTH_TOKEN, token);
  }

  const {
    data: currentUserSessionData,
    error,
  } = useQuery(GET_CURRENT_USER_SESSION, { suspend: true, skip: !token })
  const { data: currentCustomerData } = useQuery(GET_CURRENT_CUSTOMER, {
    suspend: true,
    skip: !token,
  })

  const emptyData = () =>
    props.children({
      currentCustomer: undefined,
      currentUserSession: undefined,
      featureFlags: undefined
    })

  if (!token) {
    return emptyData()
  }

  if (error) {
    console.error(error)
    sessionStorage.removeItem(SessionStorageKeys.AUTH_TOKEN)
    localStorage.removeItem(SessionStorageKeys.AUTH_TOKEN);
    return emptyData()
  }


  return props.children({
    currentUserSession: currentUserSessionData?.currentUserSession,
    currentCustomer: currentCustomerData?.currentCustomer,
    featureFlags: {
      isFEUser: currentUserSessionData?.currentUserSession.user.isFEUser,
      isBitnetUser: currentUserSessionData?.currentUserSession.user.isBitnetUser
    }
  })
}


interface AppContextDataProviderProps {
  children: (props: FuncAsAChildProps) => JSX.Element
}

interface FuncAsAChildProps {
  currentUserSession?: GetCurrentUserSession_currentUserSession
  currentCustomer?: GetCurrentCustomer_currentCustomer
  featureFlags: TFeatureFlag
}

export default AppContextDataProvider
