import * as React from "react"
import * as styles from "./styles.scss"
import Toolbar from "./Toolbar"
import { DropdownOption } from "components/DropdownButton"
import ImagePreview from "./ImagePreview"
import {
  GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle,
} from "schema"
import Whiteframe from "components/Whiteframe"
import { produce } from "immer"
import { useContextSelector } from "use-context-selector"
import { OrderEditorContext } from "../OrderEditoryAdvert"
import cx from "classnames"
import { AppContext } from "App"

const OrderPreview: React.FC<{
  refetch: () => void
  formatCollection: GetOrderById_orders_mediaOrder_documentFormat_documentFormatCollection
}> = (props) => {
  const { featureFlags } = React.useContext(AppContext)
  const [state, setState] = React.useState<OrderPreviewState>({
    toolbarIsVisible: true,
    previewZoom: { value: "auto", label: "Auto" },
  })
  const localZoom: { lable: string; value: number | string } = JSON.parse(
    localStorage.getItem("zoom")!
  )


  const toggleToolbarVisibility = () => {
    setState(
      produce(state, (draft) => {
        draft.toolbarIsVisible = !state.toolbarIsVisible
      })
    )
  }

  const setPreviewZoom = (previewZoom: DropdownOption) => {
    setState(
      produce(state, (draft) => {
        draft.previewZoom = previewZoom
      })
    )
    localStorage.setItem(
      "zoom",
      JSON.stringify({ lable: previewZoom.label, value: previewZoom.value })
    )
  }

  const {
    isLocked,
    selectedDocumentFormat,
    selectedCaseFolderId,
    previewImageUrl,
    publishTime,
    orderId,
    selectedOrderId,
    togglePreviewIsUpdatingOn,
    togglePreviewIsUpdatingOff,
    userInterface,
    selectedDocumentFormatId
  } = useContextSelector(OrderEditorContext, (s) => {
    return {
      isLocked: s.isLocked,
      selectedDocumentFormat:
        s.selectedOrder.mediaOrder?.documentFormat.mediaAdaptedName,
      selectedCaseFolderId: s.selectedCaseFolderId,
      orderId: s.selectedOrder.id ?? "",
      publishTime: s.selectedOrder.mediaOrder?.publishTimes[0] ?? null,
      previewImageUrl: s.previewImageUrl,
      selectedOrderId: s.selectedOrder.id ?? "",
      togglePreviewIsUpdatingOn: s.togglePreviewIsUpdatingOn,
      togglePreviewIsUpdatingOff: s.togglePreviewIsUpdatingOff,
      userInterface: s.userInterface,
      selectedDocumentFormatId: s.selectedOrder.mediaOrder?.documentFormat.id
    }
  })

  return (
    <Whiteframe className={cx(styles.orderPreviewContainer) }>
      <Toolbar
        isVisible={state.toolbarIsVisible}
        onToggleVisibility={toggleToolbarVisibility}
        onSetPreviewZoom={setPreviewZoom}
        previewZoom={state.previewZoom}
        isLocked={isLocked}
        selectedDocumentFormat={selectedDocumentFormat}
        selectedCaseFolderId={selectedCaseFolderId}
        togglePreviewIsUpdatingOn={togglePreviewIsUpdatingOn}
        togglePreviewIsUpdatingOff={togglePreviewIsUpdatingOff}
        selectedOrderId={selectedOrderId}
        userInterface={userInterface}
        localZoom={localZoom !== null && localZoom}
        formatCollection={props.formatCollection}
        selectedDocumentFormatId={selectedDocumentFormatId}
        isFEUser={featureFlags?.isFEUser!}
      />
      <ImagePreview
        toolbarIsVisible={state.toolbarIsVisible}
        zoom={localZoom !== null ? localZoom.value : state.previewZoom.value}
        isLocked={isLocked}
        previewImageUrl={previewImageUrl}
        publishTime={publishTime}
        orderId={orderId}
        isFEUser={featureFlags?.isFEUser!}
      />
      {/* <Footer selectedOrder={selectedOrder} isLocked={isLocked} /> */}
    </Whiteframe>
  )
}

interface OrderPreviewState {
  toolbarIsVisible: boolean
  previewZoom: DropdownOption
}

export interface OrderPreviewRectangle
  extends GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_rectangle {
  id: string
}

export default OrderPreview
