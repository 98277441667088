import * as React from "react"
import { useHistory, useLocation } from "react-router"
import DeleteOrderModal from "./OrderEditorContainer/OrderPreview/Toolbar/Hamburger/DeleteOrderModal"

export const SEARCH_KEY_VALUE = "delete_order"
export const SEARCH_KEY = "action"
export const DeleteOrderModalBlock: React.FC<{
  action: any
  caseFolderId: string
  orderId?: string
}> = (props) => {
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const action = params.get(SEARCH_KEY)
  const defaultshowModal = action !== undefined && action === SEARCH_KEY_VALUE
  const [showModal, setShowModal] = React.useState<boolean>(defaultshowModal)
  const onCloseModal = () => {
    setShowModal(false)
    const urlParams = new URLSearchParams(location.search)
    urlParams.delete(SEARCH_KEY)
    const newSearch = urlParams.toString()
    history.push({
      search: newSearch,
    })
    localStorage.removeItem('newsPapper')
    localStorage.removeItem('firstOrder')
  }

  React.useEffect(() => {
    const _params = new URLSearchParams(location.search)
    const _action = _params.get(SEARCH_KEY)
    const _newShowModal = _action !== undefined && _action === SEARCH_KEY_VALUE
    setShowModal(_newShowModal)
  }, [location.search])

  if (showModal === false) {
    return null
  }

  return (
    <DeleteOrderModal
      isOpen={showModal}
      onRequestClose={onCloseModal}
      caseFolderId={props.caseFolderId}
      orderId={props.orderId}
    />
  )
}
