import gql from "graphql-tag"
import DASHBOARD_CASE_FOLDER_FRAGMENT from "graphql/fragments/DashboardCaseFolderFragment"
import EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT from "graphql/fragments/EditOrderPageCaseFolderFragment"

const DELETE_ORDER = gql`
  mutation DeleteOrder($input: MutationDeleteOrderInput!) {
    deleteOrder(input: $input) {
      isError
      errorReason
      caseFolders {
        ...EditOrderPageCaseFolderFragment
        ...DashboardCaseFolderFragment
      }
    }
  }
  ${DASHBOARD_CASE_FOLDER_FRAGMENT}
  ${EDIT_ORDER_PAGE_CASE_FOLDER_FRAGMENT}
`

export default DELETE_ORDER
