import * as React from "react"
// import DocumentContainer from "./DocumentContainer"
import { notEmpty } from "utils"
import * as background from "images/image.png"
import { RouteComponentProps, withRouter } from "react-router-dom"
import BackgroundImage from "components/BackgroundImage"
import MainLayout from "layouts/MainLayout"
import OrderEditorContainer from "./OrderEditorContainer"
import OrderSelection from "./OrderSelection"
import { useTranslation, withTranslation, WithTranslation } from "react-i18next"
import Header from "components/Header"
import { useApolloClient, useQuery } from "react-apollo-hooks"
import GET_EDIT_ORDER_PAGE_CASE_FOLDER from "graphql/queries/GetEditOrderPageCaseFolder"
import {
  GetEditOrderPageCaseFolder,
  GetEditOrderPageCaseFolderVariables,
  GetEditOrderPageCaseFolder_caseFolder_editTabs_orders_client_route,
} from "schema"
import ErrorMessage from "components/ErrorMessage"
import Spinner from "components/Spinner"
import { DeleteOrderModalBlock } from "./DeleteOrderModalBlock"
import { AddProductGroupOrderModal } from "../../components/AddProductGroupOrderModal/AddProductGroupOrderModal"
import { useParams } from "react-router"
import { AppContext } from "App"

const EditOrderPage: React.FC<{}> = (props) => {
  const { t } = useTranslation(["editOrder"])
  const { featureFlags } = React.useContext(AppContext)
  const params = useParams<{
    caseFolderId: string
    orderId: string
    action?: string
  }>()
  // const params = this.props.match.params as any
  const caseFolderId = params.caseFolderId
  const orderId = params.orderId
  const action = params.action

  return (
    <MainLayout
      showUtilityBar={true}
      isFEUser={featureFlags?.isFEUser!}
    >
      <BackgroundImage src={background} />
      {/* <Header title={t("editProducts")} subtitle={t("editProductsSubtitle")} /> */}

      <PageContent
        action={action}
        orderId={orderId}
        caseFolderId={caseFolderId}
        isFEUser={featureFlags?.isFEUser!}
      />
    </MainLayout>
  )
}

const PageContent: React.FC<{
  action: any
  orderId: string
  caseFolderId: string
  isFEUser: boolean
}> = (props) => {
  const client = useApolloClient()

  const { loading, data, error, refetch } = useQuery<
    GetEditOrderPageCaseFolder,
    GetEditOrderPageCaseFolderVariables
  >(GET_EDIT_ORDER_PAGE_CASE_FOLDER, {
    client,
    variables: {
      id: props.caseFolderId,
    },

    context: {},
    fetchPolicy: "network-only",
  })

  if (error) {
    return <ErrorMessage message={error.message} />
  }

  if (loading) {
    return <Spinner fullscreen={true} />
  }

  const editTabs = data?.caseFolder?.editTabs ?? []
  const caseFolderName = data?.caseFolder?.name ?? ""
  const orders = editTabs
    .map((doc: any) => doc.orders.map((x: any) => x))
    .reduceRight((acc, currVal) => acc.concat(currVal), [])
    .filter(notEmpty)

  let selectedDocumentId
  if (props.orderId) {
    const order =
      orders.filter((e: any) => e.id === props.orderId)?.[0] ?? undefined
    if (order) {
      selectedDocumentId = order.document.id ?? undefined
    }
  }

  return (
    <>
      {!props.isFEUser && (
        <OrderSelection
          editTabs={editTabs as any}
          documentId={props.orderId}
          action={props.action}
          caseFolderId={props.caseFolderId}
          refetch={refetch}
        />
      )}
      {props.orderId && (
        <OrderEditorContainer
          orderId={props.orderId}
          orders={orders}
          editTabs={editTabs}
          documentId={selectedDocumentId}
          caseFolderId={props.caseFolderId}
          caseFolderName={caseFolderName}
        />
      )}

      <DeleteOrderModalBlock
        action={props.action}
        caseFolderId={props.caseFolderId}
        orderId={props.orderId}
      />

      <AddProductGroupOrderModal
        caseFolderId={props.caseFolderId}
        documentId={selectedDocumentId}
        orderId={props.orderId}
      />
    </>
  )
}

export default EditOrderPage
