import * as React from "react"
import * as styles from "./styles.scss"
import ImageSlider from "./ImageSlider"
import SearchSymbolButton from "./SearchSymbolButton"
import ImageSelectorModal from "components/ImageSelectorModal"
import { OrderEditorAreaContext } from ".."
import ImageControlAdminSection from "./ImageControlAdminSection"
import AdminSectionToggler from "../AdminSectionToggler"
import { AppContext } from "App"
import { produce } from "immer"
import {
  EditModelAdvertAreaContentEditorBehaviours,
  EditModelAdvertAreaContentRepository,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor,
} from "schema"
import DropdownButton from "components/DropdownButton"
import { useTranslation } from "react-i18next"
import {
  ImageSelectDialogFile,
  SliderType,
} from "../../../../../../../../customSchema.interface"
import CheckboxField from "components/CheckboxField"
import cx from "classnames"
import ImageModificationDialog from "./ImageModificationDialog"
import { useDropzone } from "react-dropzone"
import UploadSvg from "images/ladda_upp.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faCopy,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import Button from "components/Button"
import { useParams } from "react-router"
import { UploadIcon } from "asset/svg/SvgComponents"

interface State {
  isOpen: boolean
  adminSectionIsExpanded: boolean
}

const ImageControl: React.FC<{
  onSelectBlockStyle: any
  blockStyles: any[]
  contentRows?: any
  templateAreaId: string | undefined
  behaviour: EditModelAdvertAreaContentEditorBehaviours
  documentFormatId: string
  mediaId: string | null | undefined
  documentCollectionName: string | undefined
  selectedOrderId: string
}> = (props) => {
  const [state, setState] = React.useState<State>({
    isOpen: false,
    adminSectionIsExpanded: false,
  })
  const [isDefault, setIsDefault] = React.useState<boolean>(false)
  const [isCopyClicked, setIsCopyClicked] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState<null | string>(null)
  const [t] = useTranslation("editOrder")
  const {featureFlags, currentUserSession} = React.useContext(AppContext);

  const openModal = () =>
    setState(
      produce(state, (draft) => {
        draft.isOpen = true
      })
    )

  const closeModal = () =>
    setState(
      produce(state, (draft) => {
        draft.isOpen = false
      })
    )

  const toggleAdminSectionIsExpanded = () =>
    setState(
      produce(state, (draft) => {
        draft.adminSectionIsExpanded = !draft.adminSectionIsExpanded
      })
    )
  React.useEffect(() => {
    if (isCopyClicked) {
      setTimeout(() => setIsCopyClicked(false), 2000)
    }
    if (errorMsg !== null) {
      setTimeout(() => {
        setErrorMsg(null)
      }, 2000)
    }
  }, [isCopyClicked, errorMsg])

  return (
    <OrderEditorAreaContext.Consumer>
      {({
        onSymbolSave,
        onSymbolResize,
        onToggleFreeSize,
        onEditImageUpload,
        onSymbolUploadHeight,
        onOriginalImageUpload,
        editor,
        contentRows,
        isLocked,
        maskImageUrl,
      }) => {
        let imageThumbnaill100x80Url: string | undefined
        let imageId: string | undefined
        let imagePublicId: string | undefined
        let imageHeight: number | null = null
        let maxImageHeight: number | undefined
        let minImageHeight: number | undefined
        let repository: EditModelAdvertAreaContentRepository

        // console.log('co wybrano selectedSymbol?', selectedSymbol)
        // let symbolSize: number = 0
        const selectedSymbol =
          contentRows?.[0]?.columns?.[0]?.item?.image ?? undefined
        if (selectedSymbol) {
          selectedSymbol.heightMM === null
            ? (imageHeight = null)
            : (imageHeight = selectedSymbol.heightMM)

          // selectedSymbol.heightMM != null
          //   ? (imageHeight = selectedSymbol.heightMM)
          //   : (imageHeight = selectedSymbol.defaultHeightMM)
          if (selectedSymbol.repository) {
            repository = selectedSymbol.repository
          }
          if (selectedSymbol.imageThumbnaill100x80Url) {
            imageThumbnaill100x80Url = selectedSymbol.imageThumbnaill100x80Url
          }
          if (selectedSymbol.imageId) {
            imageId = selectedSymbol.imageId
          } else {
            //use default value if imageId is undfind
            imageId = "144ae319-b0d8-11e8-b345-00505681222b"
            setIsDefault(true)
          }
          if (selectedSymbol.imagePublicId) {
            imagePublicId = selectedSymbol.imagePublicId
          } else {
            // use default value if imagePublicId is undfind
            imagePublicId = " "
            setIsDefault(true)
          }
        } else {
          // imageHeight = 0
          // if (editor.image && editor.image.maxImageHeight) {
          //   imageHeight = editor.image.maxImageHeight
          // } else {
          //   imageHeight = 0
          // }
        }

        if (editor) {
          if (editor.image) {
            if (editor.image?.maxImageHeight) {
              maxImageHeight = editor.image?.maxImageHeight
            }
            if (editor.image?.minImageHeight) {
              minImageHeight = editor.image?.minImageHeight
            }
          }
        }

        return (
          <div
            className={cx(styles.imageArea, {
              [styles.isLockedActive]: isLocked,
              [styles.feView_imageArea]: featureFlags?.isFEUser
            })}
          >
            <ImageControlContent
              isSymbolSelected={selectedSymbol !== undefined}
              imageThumbnaill100x80Url={imageThumbnaill100x80Url}
              imageId={imageId}
              imagePublicId={imagePublicId}
              imageHeight={imageHeight}
              maxImageHeight={maxImageHeight}
              minImageHeight={minImageHeight}
              defaultHeightMM={selectedSymbol?.defaultHeightMM}
              onSymbolResize={onSymbolResize}
              onSymbolSave={onSymbolSave}
              openModal={openModal}
              closeModal={closeModal}
              isOpen={state.isOpen}
              adminSectionIsExpanded={state.adminSectionIsExpanded}
              toggleAdminSectionIsExpanded={toggleAdminSectionIsExpanded}
              isDefault={isDefault}
              editor={editor}
              blockStyles={props.blockStyles}
              onSelectBlockStyle={props.onSelectBlockStyle}
              isSizeFree={selectedSymbol?.isSizeFree}
              onToggleFreeSize={onToggleFreeSize}
              onEditImageUpload={onEditImageUpload}
              onOriginalImageUpload={onOriginalImageUpload}
              resolution={selectedSymbol!?.resolution}
              onSymbolUploadHeight={onSymbolUploadHeight}
              maskImageUrl={maskImageUrl}
              behaviour={props.behaviour}
              templateAreaId={props.templateAreaId!}
              documentFormatId={props.documentFormatId}
              repository={repository!}
              mediaId={props.mediaId}
              documentCollectionName={props.documentCollectionName}
              selectedOrderId={props.selectedOrderId}
              isSupportUser={currentUserSession.user?.isSupportUser!}
              isFEUser={featureFlags?.isFEUser!}
            />
            {isLocked && (
              <div className={styles.copyWrapper}>
                <div
                  className={styles.copyBtn}
                  onClick={() => {
                    if (
                      navigator.clipboard !== undefined &&
                      navigator.clipboard !== null &&
                      navigator.clipboard.writeText !== undefined &&
                      navigator.clipboard !== null &&
                      imagePublicId
                    ) {
                      navigator.clipboard
                        .writeText(imagePublicId!)
                        .then((res) => setIsCopyClicked(true))
                        .catch((error) => setIsCopyClicked(false))
                    } else {
                      setErrorMsg("Kopiering Misslyckades")
                    }
                  }}
                >
                  {isCopyClicked ? (
                    <>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "#1ee498" }}
                      />
                      <span style={{ color: "#1ee498" }}>Kopierad!</span>
                    </>
                  ) : errorMsg === null ? (
                    <>
                      <FontAwesomeIcon icon={faCopy} />
                      <span>
                        {t("editOrder:copySymbolNumber")} {imagePublicId}
                      </span>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        style={{ color: "red" }}
                      />
                      <span style={{ color: "red" }}>{errorMsg}</span>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      }}
    </OrderEditorAreaContext.Consumer>
  )
}

const ImageControlContent: React.FC<{
  onToggleFreeSize: (value: boolean) => void
  isSymbolSelected: boolean
  imageThumbnaill100x80Url: string | undefined
  imageId: string | undefined
  imagePublicId: string | undefined
  imageHeight: number | null
  minImageHeight?: number
  maxImageHeight?: number
  onSymbolSave: any
  onEditImageUpload: any
  onOriginalImageUpload: (
    data: any,
    filename?: string,
    comment?: string,
    callback?: () => void
  ) => void
  onSymbolResize: any
  isOpen: boolean
  openModal: any
  closeModal: any
  adminSectionIsExpanded: boolean
  toggleAdminSectionIsExpanded: any
  isDefault: boolean
  editor?: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor
  onSelectBlockStyle: any
  blockStyles: any[]
  defaultHeightMM?: number | null
  isSizeFree: boolean | null | undefined
  resolution: any
  onSymbolUploadHeight: any
  maskImageUrl: string | undefined | null
  behaviour: EditModelAdvertAreaContentEditorBehaviours
  templateAreaId: string
  documentFormatId: string
  repository: EditModelAdvertAreaContentRepository
  mediaId: string | null | undefined
  documentCollectionName: string | undefined
  selectedOrderId: string
  isSupportUser: boolean
  isFEUser: boolean
}> = (props) => {
  const { t } = useTranslation(["editOrder"])
  const { orderId } = useParams() as { orderId: string }
  const { isEnabledUpload } = props.editor?.image ?? {}
  const {
    GALLERY_IMAGE,
    ORDER_IMAGE,
  } = EditModelAdvertAreaContentEditorBehaviours
  
  const isSupportUser = props.isSupportUser

  const [loading, setLoading] = React.useState(false)
  const [stateIsSizeFree, setStateIsSizeFree] = React.useState<boolean>(
    props.isSizeFree!
  )
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null)
  const [state, setState] = React.useState<{
    slider: SliderType
  }>({
    slider: {
      scale: 0.4,
      value: 0,
    },
  })
  const [isModifictionDialog, setIsModificationDialog] = React.useState<
    boolean
  >(false)
  const [
    UploadedFile,
    setUploadedFile,
  ] = React.useState<ImageSelectDialogFile | null>(null)
  const [isEditButtonClicked, setEditButtonClick] = React.useState<boolean>(
    false
  )
  const [isCursorDragActive, setCursorDragActive] = React.useState<boolean>(
    false
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",

    onDropAccepted: (uploadedFile: Array<any>) => {
      let file = uploadedFile[0]
      const imageSelectDialogFile: ImageSelectDialogFile = {
        fileFormInput: file,
        name: file.name,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        size: file.size,
        path: file.path,
        preview: URL.createObjectURL(file),
      }
      setUploadedFile(imageSelectDialogFile)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener("load", () => {
        try {
          sessionStorage.setItem(`${orderId}@imageFile`, reader.result as any)
        } catch (e) {
          console.log("error", e)
        }
        setIsModificationDialog(true)
        setCursorDragActive(false)
      })
    },
  })

  React.useEffect(() => {
    getImageFromSessionStorage()
  }, [])

  const getImageFromSessionStorage = () => {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes(`${orderId}@imageFile`)) {
        const url = sessionStorage.getItem(key)
        if (url) {
          fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
              const file = new File([blob], "File name", { type: "image/png" })
              const imageSelectDialogFile: ImageSelectDialogFile = {
                fileFormInput: file,
                name: file.name + ".jpg",
                type: file.type,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModified,
                size: file.size,
                path: file.name + ".jpg",
                preview: URL.createObjectURL(file),
              }
              setUploadedFile(imageSelectDialogFile)
            })
        }
      }
    })
  }

  const onSuccessCropFromModal = (blob: any) => {
    setLoading(true)
    // const preview = URL.createObjectURL(blob)
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64data = reader.result?.toString() ?? undefined
      if (!base64data) {
        setErrorMsg(`${t("errorMessages:unknowError")}`)
        return
      }
      props.onEditImageUpload(base64data, UploadedFile?.name, (data: any) => {
        if (data) {
          setIsModificationDialog(false)
          // setUploadedFile(null)
          setLoading(false)
        }
      })
    }
    reader.readAsDataURL(blob)
  }

  const onToggleFreeSize = () => {
    setStateIsSizeFree(!stateIsSizeFree)
    updateFreeSize(!stateIsSizeFree)
  }

  const updateFreeSize = (value: boolean) => {
    props.onToggleFreeSize(value)
  }

  const onClear = () => {
    props.onSymbolResize(null)
    setState({
      slider: {
        value: null,
        scale: null,
      },
    })
  }

  const HandleModifictionDialogOpen = () => {
    setIsModificationDialog(true)
    setEditButtonClick(true)
  }
  const HandleModifictionDialogClose = () => setIsModificationDialog(false)

  return (
    <React.Fragment>
      {props.blockStyles.length > 0 ? (
        <div>
          <DropdownButton
            rightIcon="angle-down"
            label={"Block styles"}
            options={props.blockStyles.map((e) => {
              return {
                label: e.name,
                value: e.id,
              }
            })}
            togglerClassName={styles.dropdownStyles}
            onOptionClick={(option) => {
              props.onSelectBlockStyle(option.value)
            }}
          />
        </div>
      ) : null}

      <div
        className={cx(styles.imageControlContainer, {
          [styles.isDragActive]: isCursorDragActive,

        })}
        onDragEnterCapture={(e) => {
          setCursorDragActive(true)
        }}
        onDragLeaveCapture={(e) => {
          setTimeout(() => {
            setCursorDragActive(false)
          }, 750)
        }}
      >
        {props.isSymbolSelected && props.imageId ? (
          <ImageSlider
            selectedSymbolUrl={props.imageThumbnaill100x80Url}
            maxImageHeight={props.maxImageHeight}
            minImageHeight={props.minImageHeight}
            onResize={props.onSymbolResize}
            onOpenModal={props.openModal}
            symbolSize={props.imageHeight}
            defaultHeightMM={Math.round(props.defaultHeightMM!)}
            state={state}
            setState={setState}
            isSizeFree={stateIsSizeFree}
            isSupportUser={isSupportUser}
            resolution={props.resolution}
            isOrderImage={props.behaviour !== ORDER_IMAGE}
            isFEUser={props.isFEUser}
          />
        ) : null}

        <div className={styles.buttonContainer}>
          {props.behaviour !== ORDER_IMAGE && (
            <>
              <SearchSymbolButton onClick={props.openModal} />
              <span className={styles.symbolPublicId}>
                {props.imagePublicId && "Nr. " + props.imagePublicId}
              </span>
              {props.isSymbolSelected ? (
                <>
                  {props.imageHeight !== null && (
                    <button
                      className={styles.resetSize}
                      onClick={onClear}
                      disabled={stateIsSizeFree && !isSupportUser}
                    >
                      {t("resetSize")}
                    </button>
                  )}
                </>
              ) : null}
            </>
          )}

          {isEnabledUpload ? (
            <>
              {UploadedFile && (
                <Button onClick={HandleModifictionDialogOpen}>
                  {t("edit")}
                </Button>
              )}

              <div
                onClick={(e) => e.stopPropagation()}
                id={"upload-input"}
                className={cx(styles.dropzoneWrapper, {
                  [styles.feView__dropzoneWrapper]: props.isFEUser
                })}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div className={styles.uploadByDrop}>
                    {React.cloneElement(<UploadIcon />, {
                      size: "75px",
                    })}
                  </div>
                ) : (
                  <div className={styles.uploadByDrop}>
                    {React.cloneElement(<UploadIcon />, {
                      size: "75px",
                    })}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className={cx(styles.outerDropZoneWrapper, {
            [styles.isDragActive]: isCursorDragActive,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <></>
          ) : 
          null
          }
        </div>
        {isModifictionDialog && (
          <ImageModificationDialog
            onSymbolUploadHeight={props.onSymbolUploadHeight}
            errorMsg={errorMsg}
            isOpen={isModifictionDialog}
            onClose={HandleModifictionDialogClose}
            imageDetails={UploadedFile}
            maskImageUrl={props.maskImageUrl}
            adjustmentValues={props.editor?.image?.adjustment}
            onSave={(blob: any) => {
              if (onSuccessCropFromModal) {
                onSuccessCropFromModal(blob)
              }
            }}
            isOrderImage={
              //basheer
              //props.behaviour === ORDER_IMAGE
              true
            }
            isStandardAdvert={props.behaviour === GALLERY_IMAGE}
            onOriginalImageUpload={props.onOriginalImageUpload}
            isEditButtonClicked={isEditButtonClicked}
            loading={loading}
            documentCollectionName={props.documentCollectionName}
            selectedOrderId={props.selectedOrderId}
            isFEUser={props.isFEUser}
          />
        )}

        {props.isSymbolSelected && props.imageId ? (
          <>
            <ImageSelectorModal
              onSave={props.onSymbolSave}
              onRequestClose={props.closeModal}
              isOpen={props.isOpen}
              selectedSymbolId={props.imageId}
              editor={props.editor}
              templateAreaId={props.templateAreaId}
              documentFormatId={props.documentFormatId}
              repository={props.repository}
              mediaId={props.mediaId}
            />
          </>
        ) : null}
        {props.behaviour !== ORDER_IMAGE && (
          <>
            {isSupportUser && props.editor?.image?.isEnabledFreeSize ? (
              <CheckboxField
                checked={stateIsSizeFree}
                onChange={onToggleFreeSize}
                label={stateIsSizeFree ? t("sizeIsUnlock") : t("lockSize")}
                className={cx(styles.checkFreeSize,{
                  [styles.feView__checkFreeSize]: props.isFEUser
                })}
              />
            ) : null}
          </>
        )}

        <AppContext.Consumer>
          {({ currentUserSession }) =>
            currentUserSession &&
            currentUserSession.user &&
            currentUserSession.user.isSupportUser && (
              <React.Fragment>
                <div className={styles.adminTogglerContainer}>
                  <AdminSectionToggler
                    onClick={props.toggleAdminSectionIsExpanded}
                    isExpanded={props.adminSectionIsExpanded}
                  />
                </div>

                <ImageControlAdminSection
                  selectedSymbolUrl={
                    props.imageThumbnaill100x80Url! || UploadedFile?.preview
                  }
                  isExpanded={props.adminSectionIsExpanded}
                />
              </React.Fragment>
            )
          }
        </AppContext.Consumer>
      </div>
    </React.Fragment>
  )
}

export default ImageControl
