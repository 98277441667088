import * as React from "react"
import * as styles from "./styles.scss"
import { notEmpty } from "utils"
// import AreasDropdown from "./AreasDropdown"
import UpdateAllContainer from "./UpdateAllContainer"
import AreaContainer from "./AreaContainer"
import {
  EditModelAdvertAreaContentEditorTypes,
  GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas,
  UpdateOrderContent,
  UpdateOrderContentVariables,
  GetEditOrderPageCaseFolder_caseFolder_editTabs,
  GetOrderById_orders,
} from "schema"
import { OrderEditorContext } from "../.."
// import NextStep from "./NextStep"
import { produce } from "immer"
import Whiteframe from "components/Whiteframe"
import UPDATE_ORDER_CONTENT from "graphql/mutations/updateOrderContentValues"
import {
  MutationUpdaterFn,
  useApolloClient,
  useMutation,
} from "react-apollo-hooks"
import { useContextSelector } from "use-context-selector"
import { cloneDeep } from "lodash"
// import Button from "components/Button"
import AddArea from "./AddArea"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { log } from "appLog"
import { useTranslation } from "react-i18next"

const Areas: React.FC<AreasProps> = (props) => {
  const {
    expandedAreaId,
    setExpandedAreaId,
    setHoveredAreaId,
    togglePreviewIsUpdatingOn,
    togglePreviewIsUpdatingOff,
    selectedOrderId,
    isLocked,
  } = useContextSelector(OrderEditorContext, (s) => {
    return {
      expandedAreaId: s.expandedAreaId,
      setExpandedAreaId: s.setExpandedAreaId,
      setHoveredAreaId: s.setHoveredAreaId,
      togglePreviewIsUpdatingOn: s.togglePreviewIsUpdatingOn,
      togglePreviewIsUpdatingOff: s.togglePreviewIsUpdatingOff,
      selectedOrderId: s.selectedOrder.id ?? "",
      isLocked: s.isLocked,
      selectedCaseFolderId: s.selectedCaseFolderId,
    }
  })
  // const [requestTrackerId, setRequestTrackerId] = React.useState<string>(
  //   generateUUID()
  // )
  const [isVisible, setIsVisible] = React.useState(false)
  const [isSeparatorRowAdded, setIsSeparatorRowAdded] = React.useState(false)
  const client = useApolloClient()
  const [state, setState] = React.useState<AreasState>({
    editorAreas: {},
    visibleAreas: {},
  })
  const { t } = useTranslation()

  const updateOrderContentValues = useMutation<
    UpdateOrderContent,
    UpdateOrderContentVariables
  >(UPDATE_ORDER_CONTENT, {
    client,
    context: {
      debounceKey: "1",
      debounceTimeout: 500,
    },
  })

  // Changes Kenneth 20210510 : Store the variable for the mutation globaly, and use it in the mutation updates
  // TODO I suppose global variables can be declared somehow
  function setLatestMutationVariables(
    value: UpdateOrderContentVariables | null | undefined
  ) {
    ;(window as any).latestMutationVariables = value
  }
  function latestMutationVariables():
    | UpdateOrderContentVariables
    | null
    | undefined {
    return (window as any).latestMutationVariables
  }

  const debug = false

  const onUpdateCache: MutationUpdaterFn<UpdateOrderContent> = (
    cache,
    { data }
  ) => {
    /**
     * Changes Kenneth 20210510 this procedure force the output to be the input.
     *  This is crusial for get the editors work properly
     *  The problem that I try to solve here, is when  backend is requested,
     *  in the same time a debounce request are waiting to be requested.
     *  The result from backend in the first call, affect the changes made
     *  in the editors during the meantime it was request.
     *  So, for not making the result disturb editors, I force the outcome to be the lastest.
     */
    const latestVars = latestMutationVariables()
    if (latestVars) {
      const newData = produce(data, (draft) => {
        if (
          draft?.updateOrderContent?.order?.mediaOrder?.edit?.editModelAdvert
            ?.basePage?.areas
        ) {
          const areas: any = draft?.updateOrderContent.order.mediaOrder?.edit.editModelAdvert?.basePage.areas?.map(
            (draftArea) => {
              const areaId = draftArea?.content?.value?.id ?? null

              if (areaId != null && draftArea?.content?.value) {
                const latestArea =
                  latestVars?.input?.editModel?.editModelAdvertAreaContentValues?.filter(
                    (q) => q?.id === areaId
                  )?.[0] ?? null
                if (latestArea != null) {
                  const newAreaValue = cloneDeep(draftArea.content.value)
                  // newAreaValue.visible = latestArea.visible
                  newAreaValue.rows.forEach(
                    (rowItem: any, rowIndex: number) => {
                      rowItem.__typename = "EditModelAdvertAreaContentRow"
                      rowItem.columns.forEach(
                        (colItem: any, colIndex: number) => {
                          const lastestItem =
                            latestArea.rows[rowIndex].columns[colIndex]?.item
                          if (lastestItem) {
                            //isXmlTextReadonly I need to ensure that the xmlText updated when fields change
                            if (colItem.item?.text && !lastestItem.text!.isXmlTextReadonly) {
                              colItem.item.text.xmlText = lastestItem.text?.xmlText
                            }
                          }
                        }
                      )
                    }
                  )

                  if (debug) {
                    log(
                      "Cache update cache " +
                        newAreaValue?.rows[0].columns[0].item.text?.xmlText
                    )
                  }

                  draftArea!.content.value = newAreaValue
                }
              }

              return cloneDeep(draftArea)
            }
          )
          draft.updateOrderContent.order.mediaOrder!.edit.editModelAdvert!.basePage.areas =
            areas ?? []
        }
      })

      if (debug) {
        log(newData)
      }

      cache.writeQuery({ data: newData, query: UPDATE_ORDER_CONTENT })
    }
  }

  const triggerSaveWithAbort = (variables: {
    variables: UpdateOrderContentVariables
  }) => {
    setLatestMutationVariables(variables.variables)
    return new Promise(async (resolve, reject) => {
      try {
        if (debug) {
          log(`triggerSaveWithAbort mutation`)
        }

        const response = await updateOrderContentValues({
          variables: variables.variables,
          update: onUpdateCache,
          context: {
            requestTrackerId: "TEST",
            debounceKey: "updateOrderContentValues",
            debounceTimeout: 700,
          },
        })

        if (debug) {
          log(`triggerSaveWithAbort mutation response`)
        }
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }

  const updateEditorItems = () => {
    const {
      SEPARATOR,
      TEXT_HTML,
      GALLERY_IMAGE,
      CUSTOM,
      NONE,
    } = EditModelAdvertAreaContentEditorTypes

    const newState = produce(state, (draft) => {
      props.areas.filter(notEmpty).forEach((area) => {
        let visible: boolean = area.content.value.isEditorVisible
        if (visible) state.visibleAreas[area.id] = true
        visible = visible || state.visibleAreas[area.id] != undefined

        draft.editorAreas[area.id] = {
          area,
          visible,
        }
      })
    })
    setState(newState)
  }

  React.useEffect(() => {
    if (props.areas) {
      updateEditorItems()
    }
    if (isLocked) {
      setIsVisible(false)
    }
  }, [props.areas, isLocked])

  const addOptionToEditor = (option: { value: string; label: string }) => {
    const id = option.value
    const { TEXT_HTML, SEPARATOR } = EditModelAdvertAreaContentEditorTypes

    setState(
      produce(state, (draft) => {
        const editorType =
          draft.editorAreas[id]?.area.content.editor?.type ||
          EditModelAdvertAreaContentEditorTypes.NONE
        draft.editorAreas[id].visible = true
        draft.editorAreas[id].area.content.value.isEditorVisible = true
        // If not separator, the visible is always true
        draft.editorAreas[id].area.content.value.visible =
          editorType != EditModelAdvertAreaContentEditorTypes.SEPARATOR

        if (draft.editorAreas[id].area.content.editor) {
          if (draft.editorAreas[id].area.content.editor?.type === TEXT_HTML) {
            draft.editorAreas[id].area.content.value.rows = [
              produce(
                draft.editorAreas[id].area.content.value.rows[0],
                (d1) => {
                  if (d1.columns[0].item.text) {
                    d1.columns[0].item.text.xmlText = "<p><br/></p>"
                  }
                }
              ),
            ]
          }
        }
        if (draft.editorAreas[id].area.content.editor?.type === SEPARATOR) {
          draft.editorAreas[id].area.content.value.visible = true
          setIsSeparatorRowAdded(true)
        }
      })
    )
  }

  // discard changes about an

  const onOptionAreaOpen = () => {
    setIsVisible(!isVisible)
  }

  var selectedIndex = Math.max(
    0,
    Object.keys(state.editorAreas)
      .map((k, i) => {
        return { key: k, item: state.editorAreas[k] }
      })
      .filter((q) => q.item.visible)
      .map((item, i) => {
        return { key: item.key, index: i }
      })
      .filter((q) => q.key == expandedAreaId)[0]?.index ?? 0
  )
  return (
    <div className={styles.areasContainer}>
      <Whiteframe
        padding={false}
        className={styles.container}
        style={{ overflow: "clip" }}
      >
        <div
          className={styles.contentContainer}
          style={
            !isVisible
              ? {
                  marginTop: 0 - Math.max(selectedIndex, 3) * 60 + 3 * 60,
                  transition: "all 0.5s",
                }
              : {}
          }
        >
          {Object.keys(state.editorAreas).map((key, index) => {
            const item = state.editorAreas[key]
            if (item.visible === false) {
              return isVisible ? (
                <AddArea
                  key={key + index}
                  areas={[item.area]}
                  onOptionClick={addOptionToEditor}
                  onSetExpandedAreaId={setExpandedAreaId}
                  disabled={isLocked}
                  setIsVisible={setIsVisible}
                  isFEUser={props.isFEUser}
                />
              ) : null
            }
            const area: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas =
              item.area
            return (
              <AreaContainer
                key={area.id!}
                selectedOrderId={selectedOrderId}
                // Read comment in AreasContainer
                onUpdateOrderContentValues={triggerSaveWithAbort as any}
                onClick={setExpandedAreaId}
                onSetHoveredAreaId={setHoveredAreaId}
                onTogglePreviewIsUpdatingOn={togglePreviewIsUpdatingOn}
                togglePreviewIsUpdatingOff={togglePreviewIsUpdatingOff}
                id={area.id!}
                isExpanded={area.id === expandedAreaId}
                label={area.header}
                editor={area.content.editor!}
                area={area}
                areaExpanded={area.id === expandedAreaId ? area : undefined}
                callbackAfterUpdate={props.callbackAfterUpdate}
                editTabs={props.editTabs}
                isSeparatorRowAdded={isSeparatorRowAdded}
                setIsSeparatorRowAdded={setIsSeparatorRowAdded}
                isLocked={isLocked}
                documentFormatId={props.order.mediaOrder?.documentFormat.id}
                mediaId={props.order.mediaOrder?.media.id}
                documentCollectionName={
                  props.order.mediaOrder?.documentFormat
                    .documentFormatCollection.name
                }
                isFEUser={props.isFEUser}
              />
            )
          })}

          <UpdateAllContainer
            isEnabledUpdate={
              props.order.mediaOrder?.edit.editModelAdvert
                ?.isEnabledUpdateOrdersContent
            }
          />

          <button
            onClick={onOptionAreaOpen}
            disabled={isLocked && true}
            className={cx(
              styles.addLinesArea,
              {
                [styles.selected]: isVisible,
                [styles.disabled]: isLocked,
              },
              props.isFEUser && [
                styles.isAdstateViewEnabled,
                {
                  [styles.selected]: isVisible,
                  [styles.disabled]: isLocked,
                },
              ]
            )}
          >
            {!isVisible ? (
              <>
                <FontAwesomeIcon icon="plus-circle" />
                <span>{t("editOrder:addField")}</span>
              </>
            ) : (
              <>
                <span>{t("common:cancel")}</span>
              </>
            )}
          </button>
        </div>
      </Whiteframe>
    </div>
  )
}

interface AreasState {
  editorAreas: {
    [id: string]: {
      visible: boolean
      area: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas
    }
  }
  expandedAreaId?: string
  visibleAreas: any
}

interface AreasProps {
  areas: (GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas | null)[]
  callbackAfterUpdate?: () => void
  editTabs: GetEditOrderPageCaseFolder_caseFolder_editTabs[]
  order: GetOrderById_orders
  isFEUser: boolean
  // nodeRef: any
  // CheckOutSideDev?: boolean
}

// export default withTranslation("editOrder")(Areas)
export default Areas
